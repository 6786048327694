.organization {
  color: #4299E1 !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

.organization:hover {
  color: #9365c2 !important;
}

.glow-enabled .organization:hover {
  text-shadow: 0 0 30px rgba(147, 101, 194, 0.5);
}

.test {
  height: '10px'
}

.glass-background {
  position: relative;
  /* background: #F7FAFC; */
  /* background: #f8fbfd; */
  /* background: linear-gradient(180deg,
      #ffffff 0%,
      #fcfbf7 30%,
      #fcfbf7 70%,
      #ffffff 100%); */
  background: linear-gradient(180deg,
      #ffffff 0%,
      #F7FAFC 30%,
      #F7FAFC 70%,
      #ffffff 100%);
  overflow: hidden;
  min-height: 100vh;
}

.glass-background::before,
.glass-background::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.4;
  z-index: 0;
  will-change: transform;
}

.glass-background::before {
  background: #4299E1;
  width: 250px;
  height: 350px;
  top: 100px;
  left: 100px;
}

.glass-background::after {
  background: #4299E1;
  width: 280px;
  height: 380px;
  bottom: 100px;
  right: 100px;
}

.experience-card {
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  transition: transform 1.5s cubic-bezier(0.2, 0.8, 0.2, 1), opacity 2.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateY(80px);
  opacity: 0;
  transition-delay: 5s;
}

.experience-card.visible {
  transform: translateY(0);
  opacity: 1;
}

.experience-card.hiding {
  transform: translateY(80px);
  opacity: 0;
  transition-delay: 3s;
}

.section-title {
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(100px);
  opacity: 0;
}

.section-title.visible {
  transform: translateY(0);
  opacity: 1;
}

.section-title.hiding {
  transform: translateY(100px);
  opacity: 0;
}

.main-title {
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(100px);
  opacity: 0;
}

.main-title.visible {
  transform: translateY(0);
  opacity: 1;
}

.main-title.hiding {
  transform: translateY(100px);
  opacity: 0;
}