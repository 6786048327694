#line1 {
    font-family: 'nunito';
    margin-top: '20px';
    margin-left: '5%';
}

#textDiv {
    float: right;
    margin-top: 80px;
    width: 58%;
    /* background: rgb(255, 250, 236); */
    /* border: 6px solid rgb(154, 198, 255); */
    border-radius: 30px 0px 0px 30px;
    /* background: rgb(154, 198, 255); */
    /* background: rgb(8, 144, 255); */
}

.aboutT {
    margin-left: 30px;
    margin-right: 10px;
    font-size: 21px;
    /* line-height: 150%; */
    line-height: 138%;
    color: white;
    font-weight: 450;
    /* margin-bottom:10px; */
}

span {
    color: rgb(255, 225, 92);
    /* color:rgb(0, 126, 230); */
    font-weight: 710;
}

/* Green span */
#green {
    /* color:rgb(36, 136, 22); */
    color: rgb(182, 255, 173);
    font-weight: 695;
}

#red {
    /* color:rgb(36, 136, 22); */
    color: rgb(255, 188, 188);
    font-weight: 695;
}

#me {
    /* width:54%; */
    width: 55%;
    /* width:300px; */
    display: block;
    margin-left: auto;
    margin-right: auto;

    /* Original style for circular image */
    /* border-radius: 50%; */
    /* border: double 0.5em transparent; */

    border-radius: 8%;
    border: double 5px transparent;

    /* background-image: linear-gradient(white, white), linear-gradient(to right, #7293c2, #7293c2, #7293c2); */
    /* background: #87b0e9; */
    /* background-origin: border-box; */
    /* background-clip: content-box, border-box; */
    margin-top: -14px;
}

@keyframes slideInRight {
    from {
        transform: translateX(50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}