.linkbtns {
  /* background: blue; */
  /* blue 3b8cff 
  red f76565
  orange ff8000 
  lighter orange ff8c00 */

  background: #ff8000;
  /* background: #f76565; */

  margin-top: 10px;
  border-radius: 11px;

  border: 0px solid;
  padding: 15px;
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  flex: 0 1 260px;
  /*  No stretching: */
  margin: 40px;
  box-shadow: rgba(120, 110, 111, 0.23) 0px 7px 29px 0px;

  transform: scale(1.00);
  transition-duration: 0.25s;
}

.linkbtns:hover {
  /* box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px; */
  box-shadow: rgba(120, 110, 111, 0.4) 0px 7px 29px 0px;
  /* box-shadow: rgba(120, 110, 111, 0.46) 0px 7px 29px 0px; */
  transform: scale(0.94);
  transition-duration: 0.4s;
}

.dialoglinks {
  /* background: blue; */
  /* blue 3b8cff 
  red f76565
  orange ff8000 
  green 34aa3e
  lighter orange ff8c00 
  purple a871eb
  */

  background: #70a7ff;
  /* background: #f76565; */

  /* margin-top: 10px; */
  border-radius: 6px;

  border: 0px solid;
  padding: 10px;
  font-size: 17.5px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  flex: 0 1 260px;
  /*  No stretching: */
  margin: 40px;
  box-shadow: rgba(120, 110, 111, 0.23) 0px 7px 29px 0px;

  transform: scale(1.00);
  transition-duration: 0.25s;
}

.dialoglinks:hover {
  /* box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px; */
  box-shadow: rgba(120, 110, 111, 0.45) 0px 7px 29px 0px;
  /* box-shadow: rgba(120, 110, 111, 0.46) 0px 7px 29px 0px; */
  transform: scale(0.96);
  transition-duration: 0.3s;
}

.card {
  transform: scale(1.00);
  transition-duration: 0.6s;
}

.card:hover {
  /* box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px; */
  box-shadow: rgba(120, 110, 111, 0.4) 0px 7px 29px 0px;
  /* box-shadow: rgba(120, 110, 111, 0.46) 0px 7px 29px 0px; */
  transform: scale(0.965);
  transition-duration: 0.35s;
}

.projbtns {
  /* background: blue; */
  /* blue 3b8cff 
  red f76565
  orange ff8000 
  lighter orange ff8c00 */

  /* background: #f76565; */
  float: left;
  /* margin-top: 10px; */
  border-radius: 5px;
  border: 0px solid;

  /* padding: 4px; */

  padding-bottom: 2.5px;
  padding-top: 3.2px;
  padding-right: 5.7px;
  padding-left: 5.7px;
  /* padding: 3px; */
  font-size: 16px;
  cursor: pointer;
  flex: 0 1 260px;
  /*  No stretching: */

  /* box-shadow: rgba(120, 110, 111, 0.01) 0px 7px 29px 0px; */
  box-shadow: rgba(120, 110, 111, 0.01) 0px 2px 4px 0px;

  transform: scale(1.00);
  transition-duration: 0.25s;
}

.projbtns:hover {
  /* box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px; */
  box-shadow: rgba(120, 110, 111, 0.3) 0px 2px 4px 0px;
  /* box-shadow: rgba(120, 110, 111, 0.46) 0px 7px 29px 0px; */
  transform: scale(0.95);
  transition-duration: 0.35s;
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

.video_frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.organization {
  color: 'red'
}

.organization:hover {
  color: 'red'
}